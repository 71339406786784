import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import ActionBubble from './ActionBubble';

const Actions = ({ debug, video, actions }) => {
  const [startTime, setStartTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);
  const [time, setTime] = useState(0);
  const [translations, setTranslations] = useState([]);
  const [closestActionIndex, setClosestActionIndex] = useState(null);
  const [visibilities, setVisibilities] = useState(actions.map(() => false));
  const [bubbleGradPosition, setBubbleGradPosition] = useState(0);
  const actionRefs = useRef([]);

  const EXTRA_SPACING = 30; // Extra spacing below the 'current' action

  const debouncedSetTime = useDebouncedCallback((t) => setTime(t), 100, { maxWait: 100 });

  const handleActionClick = useCallback(
    (timestamp) => {
      const timeOffset = timestamp - startTime;
      video.goto(timeOffset);
    },
    [video, startTime],
  );

  useEffect(() => {
    setStartTime(video.getMetaData().startTime);
    setTotalTime(video.getMetaData().totalTime);
    const handleTimeUpdate = (e) => debouncedSetTime(e.payload);
    video.addEventListener('ui-update-current-time', handleTimeUpdate);
    return () => {
      // TODO: rrwebPlayer doesn't have a removeEventListener...
      // video.removeEventListener('ui-update-current-time', handleTimeUpdate);
    };
  }, [video, debouncedSetTime]);

  const findClosestActionIndex = useCallback(
    (currentTime) => {
      // The summary card exists at the end of the actions list, and has no timestamp of its own.
      // We'll decide to display it only if the current time is very close to the end of the video, otherwise
      // let's assume that there's a more relevant action to display based on a real timestamp.
      const padding = 1000; // A second of the end?
      if (currentTime - startTime + padding >= totalTime) {
        return actions.length - 1;
      }

      let closestIndex = 0;
      for (let i = 0; i < actions.length; i++) {
        if (actions[i].timestamp <= currentTime) {
          closestIndex = i;
        } else {
          break;
        }
      }
      return closestIndex;
    },
    [actions, totalTime, startTime],
  );

  // useCallback so we can pass it as a dependency to useEffect, without causing an infinite loop.
  // Otherwise it risks useEffect triggering a ton just based on this method being redefined, if re-renders occur.
  // In a stricter linter world it would complain about the dependency array if we didn't do this + add the dependencies array.
  const updatePositions = useCallback(() => {
    // Find the index of the action closest to the current video time
    const closestIndex = findClosestActionIndex(startTime + time);

    // Get the heights of all action elements
    const heights = actionRefs.current.map((ref) => (ref ? ref.offsetHeight : 0));

    // Calculate new vertical translations for each action
    const newTranslations = heights.reduce((acc, height, index) => {
      if (index < closestIndex) {
        // For actions above the current action:
        // Sum up the heights of all actions between this one and the current action so we can move them up
        acc[index] = -heights.slice(index, closestIndex).reduce((sum, h) => sum + h, 0);
      } else if (index === closestIndex) {
        // Current action is 0 - the "top".
        acc[index] = 0;
      } else {
        // For actions below the current action:
        // Sum up the heights of all actions between the current one and this one,
        // then add EXTRA_SPACING to create additional separation, to fit in the thumb icons. Looks nice, too.
        acc[index] = heights.slice(closestIndex, index).reduce((sum, h) => sum + h, 0) + EXTRA_SPACING;
      }
      return acc;
    }, []);

    setTranslations(newTranslations);
    setClosestActionIndex(closestIndex);

    // Calculate the position for the bubble gradient overlay
    const currentBubbleHeight = heights[closestIndex] || 0;
    const nextBubbleHeight = heights[closestIndex + 1] || 0;
    const gradPosition = currentBubbleHeight + nextBubbleHeight + EXTRA_SPACING;
    setBubbleGradPosition(gradPosition);

    setTimeout(() => setVisibilities(newTranslations.map(() => true)), 200);
  }, [findClosestActionIndex, startTime, time, actions.length]);

  useEffect(() => {
    updatePositions();
  }, [updatePositions]);

  return (
    <div
      className="relative h-full transition-opacity delay-100 duration-500 md:before:absolute md:before:-top-[125px] md:before:left-0 md:before:right-0 md:before:z-10 md:before:h-[50px] md:before:bg-gradient-to-t md:before:from-transparent md:before:to-white md:before:content-['']"
      style={{
        opacity: bubbleGradPosition > 0 ? 1 : 0,
      }}
    >
      {actions.map((action, index) => (
        <ActionBubble
          debug={debug}
          key={action.timestamp}
          ref={(el) => (actionRefs.current[index] = el)}
          action={action}
          startTime={startTime}
          isClosest={closestActionIndex === index}
          translateY={translations[index] || 0}
          isVisible={visibilities[index]}
          onClick={handleActionClick}
        />
      ))}
      <div
        className={`pointer-events-none absolute bottom-0 left-0 right-0 transition-all duration-300 ease-in-out ${bubbleGradPosition > 0 ? 'opacity-100' : 'opacity-0'} bg-gradient-to-t from-white via-white/20 to-transparent md:after:absolute md:after:bottom-0 md:after:left-0 md:after:right-0 md:after:h-5 md:after:bg-gradient-to-t md:after:from-white/80 md:after:to-white/20 md:after:content-['']`}
        style={{ top: `${bubbleGradPosition}px` }}
      >
        &nbsp;
      </div>
    </div>
  );
};

export default Actions;
